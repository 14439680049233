<template>
  <div class="text-center d-flex flex-column margin-container-error">
     <img src="@/assets/img/error.png" width="128" class="mx-auto"/>
     <p class="c-deep fs-52 fw-600 my-error" >
      <template v-if="typeData.TYPE_BOOKING === typeError || typeData.TYPE_WALK_IN === typeError">
      {{ $l.detectLanguage('QR Code tidak bisa digunakan') }}
      </template>
      <template v-else>
        {{ $l.detectLanguage('Tiket antrian tidak dapat digunakan')}}
      </template>
      </p>
     <div class="row mb-60 text-left">
        <div class="col-md-12">
            <p class="c-primary fs-42 fw-600 mb-4">{{$l.detectLanguage('headerError')}}</p>
            <ul class="m-0">
                <template v-if="typeData.TYPE_WALK_IN === typeError || typeData.TYPE_BOOKING === typeError">
                  <li class="c-primary fw-500 fs-38 text-justify">{{ $l.detectLanguage('Anda Sebelumnya sudah melakukan scan, QR Code sudah tidak berlaku') }}</li>
                  <li class="c-primary fw-500 fs-38 text-justify">{{ $l.detectLanguage('QR Code bukan untuk jadwal hari ini') }}</li>
                  <li class="c-primary fw-500 fs-38 text-justify">{{ $l.detectLanguage('Kode Booking mungkin sudah dibatalkan')}}</li>
                  <li class="c-primary fw-500 fs-38 text-justify">{{ $l.detectLanguage('Kode Booking atau nomor telepon diatas salah') }}</li>
                </template>
                <template v-else>
                  <li class="c-primary fw-500 fs-38 text-justify">{{ $l.detectLanguage('Tiket antrian bukan untuk jadwal di hari ini') }}</li>
                  <li class="c-primary fw-500 fs-38 text-justify">{{ $l.detectLanguage('Tiket antrian mungkin sudah tidak berlaku') }}</li>
                  <li class="c-primary fw-500 fs-38 text-justify">{{ $l.detectLanguage('QR code yang Anda scan tidak sesuai') }}</li>
                </template>
            </ul>
        </div>
     </div>
     <div class="bottom-info mb-auto">

     <div class="row mb-40">
        <div class="col-md-1">
            <img src="@/assets/img/information.png" class="mt-3"  width="32" alt="">
        </div>
        <div class="col-md-11 c-primary fw-600 fs-32 text-justify">
            {{$l.detectLanguage('footerError')}}
        </div>
     </div>
      <button-time-out class="fs-32" :time="timeOut" @click="goToRoute()"/>
     </div>
  </div>
</template>

<script>
import ButtonTimeOut from '../Button/ButtonTimeOut.vue'
import ENUM_SETUP from '@/enum/typeSetup'

export default {
  components: {
    ButtonTimeOut
  },
  props: {
    headerError: {
      type: String,
      default: ''
    },
    detailError: {
      type: Array,
      default: () => []
    },
    typeError: {
      type: String,
      default: ''
    },
    timeOut: {
      type: Number,
      default: 25
    }
  },
  data () {
    return {
      typeData: ENUM_SETUP
    }
  },
  methods: {
    goToRoute () {
      window.machineKiosk.send({
        type: 'button:cancel'
      })
    }
  }
}
</script>

<style>

</style>
