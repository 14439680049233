<template>
  <div class="text-center">
    <img src="@/assets/img/information.png" />
    <p class="fs-52 fw-700 c-deep">
      {{ $l.detectLanguage('Nomor telepon')}}
      <span class="c-pink-dokter">{{ $filters.asteriks(phoneNumber) }}</span> {{ $l.detectLanguage('memiliki') }} {{listPasien.length}}
      {{ $l.detectLanguage('data pasien yang berbeda') }}
    </p>
    <p class="text-left fs-42 fw-500 c-primary">Silahkan pilih data pasien yang ingin Anda proses:</p>
    <!-- <div class="row">
    <div class="col-12 fs-42 fw-500 c-primary text-justify">
        Silahkan pilih jadwal booking yang ingin Anda proses:
    </div>
  </div> -->
  <div class="overflow-card-pasien container-overflow p-2">
    <div class="row mx-0 box-dokter-booking padding-telepon-dokter"
      v-for="item, index in listPasien" :key="index"
      :class="activeTicket(item) ? 'active-pasien' : ''"
      @click="selectedPasien(item)">
      <div class="d-flex w-100">
        <div class="text-left flex-grow-1">
          <span class="fs-46 fw-700 c-primary flex-grow-1">{{$filters.asteriks(item.namaPasien)}}</span>
        </div>
        <div class="mr-5 position-relative">
          <div class="position-absolute checkbox-hidden"></div>
          <input class="form-check-input checked-pasien" type="checkbox" :checked="activeTicket(item)" @click="nonCheck">
        </div>
      </div>
      <template v-for="dokter, index1 in item.list" :key="index1">
        <div class="d-flex w-100">
          <div class="text-left">
            <p class="fs-32 fw-600 c-primary">{{dokter.dokterUnit.dokterName}}</p>
            <p class="fs-32 fw-400 c-primary">{{dokter.dokterUnit.speciality ? dokter.dokterUnit.speciality : '-'}}</p>
          </div>
          <div class="ml-auto text-right">
            <span class="fs-32 fw-400 c-primary">{{convertSlice(dokter.jamMulai)}}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
    <div class="row mx-0 mt-2">
      <div class="col-6">
        <button-linear-cancel :data-time-out="delayTime" @cancel="routeTo" ref="button-cancel" />
      </div>
      <div class="col-6">
        <button class="btn-lanjutkan fs-42 fw-600 btn-block" :disabled="disabledButton" @click.stop="goTo">
          {{ $l.detectLanguage('proceed') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonLinearCancel from '@/components/Button/ButtonLinearCancel'
export default {
  computed: {
    multiplePasien () {
      return this.selectPasien
    }
  },
  props: {
    selectPasien: {
      type: Array,
      default: () => []
    },
    delayTime: {
      type: Object,
      default: () => {}
    },
    listPasien: {
      type: Array,
      default: () => []
    },
    disabledButton: {
      type: Boolean,
      default: true
    },
    phoneNumber: {
      type: String,
      default: ''
    }
  },
  components: {
    ButtonLinearCancel
  },
  mounted () {
    document.addEventListener('click', this.reset)
  },
  beforeUnmount () {
    document.removeEventListener('click', this.reset)
  },
  methods: {
    nonCheck (evt) {
      evt.preventDefault()
    },
    activeTicket (item) {
      const index = this.multiplePasien.findIndex(_item => _item.id && item.id === _item.id)
      return index >= 0
    },
    convertSlice (jam) {
      if (!jam) return 'EXTRA SLOT'
      return jam.toString().slice(0, 5)
    },
    reset (event) {
      event.preventDefault()
      this.$refs['button-cancel'].resetBackgroundLinearButton()
      window.machineKiosk.send({
        type: 'click:anywhere'
      })
    },
    routeTo () {
      // this.$router.push({ name: 'Menu' })
      this.$refs['button-cancel'].resetBackgroundLinearButton()
      window.machineKiosk.send({
        type: 'button:batalkan'
      })
    },
    goTo () {
      window.machineKiosk.send({
        type: 'button:lanjutkan'
      })
    },
    selectedPasien (item) {
      this.$refs['button-cancel'].resetBackgroundLinearButton()
      // this.selectTicket = item.id
      window.machineKiosk.send({
        type: 'select:pasient',
        data: item
      })
    }
  }
}
</script>

<style>
.padding-telepon-dokter{
  padding: 18px 40px;
  margin-bottom: 20px;
}
</style>
