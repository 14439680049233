<template>
  <div class="container-kiosk" >
      <div class="container-body-kiosk min-container-kiosk text-center" v-if="page.PAGE_SERVER_ERROR !== ticket.page && page.PAGE_PRINTER_ERROR !== ticket.page">
          <status-view :ticket-detail="ticket" @print="printing" />
      </div>
      <div class="text-center container-body-kiosk position-error-server d-flex align-items-start flex-column" v-else-if="page.PAGE_SERVER_ERROR === ticket.page">
        <div class="m-auto">
          <img src="@/assets/img/error.png" />
          <p class=" my-gagal c-deep fs-52 fw-600">
           {{ $l.detectLanguage('Gagal menampilkan data') }}
          </p>
          <p class="fs-42 c-deep fw-600 text-center">
            {{$l.detectLanguage('Silakan coba beberapa saat lagi')}}
          </p>
        </div>
        <div class="mx-auto mt-auto mb-4">
          <button-time-out class="fs-32" :time="25" @click="goToRoute()"/>
        </div>
      </div>
      <div v-else-if="page.PAGE_PRINTER_ERROR === ticket.page" class="text-center container-body-kiosk position-error-printer d-flex align-items-start flex-column" >
        <div style="text-align: center">
          <img  class="mb-24"  src="@/assets/img/information.png" />
           <p class="fs-52 fw-600 c-deep"  v-if="page.OTHER_ERROR_PRINT == errorPrinter">
            Ada Kendala pada printer
            </p>
            <p class="fs-52 fw-600 c-deep"  v-else-if="page.EMPTY_PAPER == errorPrinter">
              Kertas tiket habis
            </p>
           <div class=" my-5 px-1">
              <p class="fs-42 fw-500 c-primary text-left">
                Silakan hubungi staff kami, atau tetap buat antrian baru tanpa tiket fisik
               <br>
              </p>
              <p class="fs-42 fw-700 c-primary text-left  mt-60">
                Apakah Anda tetap ingin membuat antrian baru?
              </p>
           </div>
           <div class="row">
            <div class="col-6">
              <button-linear-cancel :data-time-out="ticket.config" @cancel="backToMenu" ref="button-cancel"/>
            </div>
            <div class="col-6">
              <button @click="afterClick" class="btn-lanjutkan btn-block fs-42 fw-600">{{$l.detectLanguage('proceed')}}</button>
            </div>
           </div>
      </div>
      </div>
  </div>
  <info-animation-down :text-info="$l.detectLanguage('Ambil tiket disini')" :is-left="true" v-if="ticket.page === page.PAGE_ANTRIAN_SUCCESS"/>
</template>

<script>
import InfoAnimationDown from '@/components/Button/InfoAnimationDown'
import StatusView from '@/components/Status/StatusView'
import { mapActions, mapMutations } from 'vuex'
import BookingDokter from '@/mappers/BookingDokter'
import ListAntrian from '@/mappers/ListAntrian'
import PrintData from '@/mappers/Print'
import { useSelector } from '@xstate/vue'
import ENUM_SETUP from '@/enum/typeSetup'
import ButtonTimeOut from '@/components/Button/ButtonTimeOut.vue'
import ButtonLinearCancel from '@/components/Button/ButtonLinearCancel'

export default {
  components: {
    InfoAnimationDown,
    ButtonTimeOut,
    StatusView,
    ButtonLinearCancel
  },
  data () {
    const ticket = (state) => {
      const selectUnit = state.context.selectUnit && state.context.selectUnit.name ? state.context.selectUnit : (state.context.selectUnit && state.context.selectUnit.list ? state.context.selectUnit.list[0] : state.context.listUnit[0])
      return {
        page: state.context.page,
        type: state.context.typeUnit,
        selectPendaftaran: state.context.selectPendaftaran,
        selectUnit,
        listBooking: state.context.listBooking,
        listTable: state.context.listTable,
        config: state.context.config.delay,
        listPasien: state.context.listBookingWithPasien,
        disabled: state.context.buttonDisabled,
        detailAntrian: state.context.detailList,
        successTicket: state.context.successTicket,
        doubleCheckIn: state.context.doubleCheck,
        param: state.context.key,
        selectPasienMultiple: state.context.selectPasienMultiple ?? [],
        errorPrinter: state.context.errorPrinter
      }
    }

    const context = useSelector(window.machineKiosk, ticket)
    return {
      isLoading: true,
      detailAntrian: {},
      listAntrian: [],
      showMode: '',
      printData: new PrintData(),
      ticket: context,
      page: ENUM_SETUP
    }
  },
  computed: {
    errorPrinter () {
      return this.ticket.errorPrinter
    }
  },
  unmounted () {
    this.emitter.off('back-to-ticket', this.getListAntrian)
    document.removeEventListener('click', this.reset)
  },
  mounted () {
    this.initMethod()
    this.emitter.on('back-to-ticket', this.getListAntrian)
    document.addEventListener('click', this.reset)
    this.emitter.on('change-create-ticket', () => {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
        this.showMode = 'create-queue'
      }, 2000)
    })
  },
  methods: {
    reset () {
      if (this.errorPrinter) {
        this.$refs['button-cancel'].resetBackgroundLinearButton()
        window.machineKiosk.send({
          type: 'click:anywhere'
        })
      }
    },
    afterClick () {
      window.machineKiosk.send({
        type: 'button:lanjutkan'
      })
    },
    backToMenu () {
      window.machineKiosk.send({
        type: 'button:batalkan'
      })
    },
    async initMethod () {
      this.isLoading = true
      if (this.$route.query && !this.$route.query.id) {
      // print antrian after get list antrian
        this.getListAntrian()
      } else if (this.$route.query.id && this.$route.query.type === 'booking') {
        this.isLoading = true
        try {
          await this.getListDokter()
            .then(_resp => _resp.data)
            .then(_data => new BookingDokter(_data))
            .then(_data => {
              this.isLoading = false
              this.detailAntrian = _data
              if (_data.today) {
                if (_data.dokter.length > 1) {
                  this.showMode = 'list_dokter'
                } else {
                  this.printData.dokter = _data.dokter
                  this.isLoading = false
                  this.getListAntrian()
                }
              } else {
                this.showMode = 'error'
              }
              return true
            }).catch(() => {
              this.isLoading = false
              this.showMode = 'error'
              // this.setErrorPage(false)
            })
        } catch {

        }
      // print kasir / booking
      } else if (this.$route.query.id && this.$route.query.type === 'antrian') {
        this.isLoading = true
        this.getAntrianInfo(this.$route.query.id)
          .then(_resp => _resp.data)
          .then(_data => {
            this.isLoading = false
            if (_data.poliWaiting || (_data.dokter_wait)) {
              this.showMode = 'antrian'
            } else {
              this.showMode = 'create-queue'
            }
            this.detailAntrian = _data
          })
          .catch(() => {
            console.log('error data')
            this.isLoading = false
            this.showMode = 'error'
            // this.setErrorPage(false)
          })
      }
    },
    async getListAntrian () {
      this.isLoading = true
      try {
        await this.getDataAfterCreate()
          .then(_resp => _resp.data)
          .then(_data => new ListAntrian(_data))
          .then(_data => {
            this.listAntrian = _data.list
            this.showMode = 'create'
            // this.printingData(this.printData)
            return true
          }).catch(() => {
            this.showMode = 'error'
          })
      } catch {
        this.showMode = 'error'
      }
      this.isLoading = false
    },
    ...mapMutations({
      setErrorPage: 'DocumentHandler/SET_ERROR_PAGE'
    }),
    ...mapActions({
      getDataAfterCreate: 'GET_AFTER_CREATE_ANTRIAN',
      getListDokter: 'GET_LIST_DOKTER_BOOKING',
      printingData: 'PRINTING_KIOSK',
      getAntrianInfo: 'GET_ANTRIAN_INFO'
    }),
    async printing (evt) {
      const dokterPrint = this.selectedPrintingDokter(this.detailAntrian.dokter, evt)
      this.printData.dokter = dokterPrint
      // this.printingData(buildToPrint)
    },
    selectedPrintingDokter (selectedDokter, detail) {
      const listDokterSelect = []
      if (selectedDokter.length > 0) {
        selectedDokter.forEach(element => {
          const index = detail.findIndex(el => el.id === element.id)
          if (index < 0 || detail[index].selected) {
            // tidak ditambah
            listDokterSelect.push(element)
          }
        })
      }
      return listDokterSelect
    },
    goToRoute () {
      window.machineKiosk.send({
        type: 'button:cancel'
      })
    }
  }
}
</script>

<style>

</style>
