<template>
  <div class="text-center" >
    <!-- <p class="fs-52 fw-700 c-deep m-0">Scan QR Code Anda,</p> -->
    <animation-check />
    <p class="fs-52 fw-700 c-deep m-0">{{ $l.detectLanguage('ANTRIAN BERHASIL DIBUAT') }}</p>
    <p class="c-primary fs-38 fw-600 mt-4"  v-html="changeOfData($l.detectLanguage('Silahkan ambil tiket antrian Anda dan menunggu di antrian'), ticketDetail.selectPendaftaran.name, '$$')"></p>
    <template v-if="isMultipleTicket">
      <table-antrian-multiple :data-list="ticketDetail.listTable" :style="ticketDetail.type === 'TYPE_UNIT' ? 'width: 90%' : 'width: 60%'"/>
    </template>
    <template v-else>
      <table-antrian :data-list="ticketDetail.listTable" :style="ticketDetail.type === 'TYPE_UNIT' ? 'width: 90%' : 'width: 60%'"/>
    </template>
    <p class="fs-38 fw-600 mt-5 c-primary">{{ $l.detectLanguage('Nomor Tiket Anda adalah') }} <span class="font-weight-bold c-pink-dokter">{{ticketDetail.listTable.ticket}}</span></p>
    <template v-if="invalidCreate == 0">
      <p class="fs-38 c-warning-A fw-600">{{ $l.detectLanguage('Periksa kembali tiket Anda dengan yang tertera di layar kiosk') }}</p>
    </template>
    <template v-else>
      <p class="fs-38 c-warning-A fw-600">{{ invalidCreate }} appointment gagal dibuatkan antrian, silakan hubungi staf pendaftaran kami</p>
    </template>
    <button-time-out :time="timeOut" @click="goToRoute()" class="fs-32"/>
  </div>
</template>
<script>
import AnimationCheck from '../Button/AnimationCheck.vue'
import ButtonTimeOut from '../Button/ButtonTimeOut.vue'
import TableAntrian from './TableAntrianResponsiveWithout.vue'
import TableAntrianMultiple from './TableAntrianMultipleResponsiveWithout.vue'
import ENUM_SETUP from '@/enum/typeSetup'
import MoreFunction from '@/helpers/moreFunction'

export default {
  data () {
    return {
      type: ENUM_SETUP
    }
  },
  computed: {
    isMultipleTicket () {
      return this.ticketDetail.selectPasienMultiple.length > 0
    },
    invalidCreate () {
      const multiplePasien = this.ticketDetail.selectPasienMultiple
      if (multiplePasien && multiplePasien.length === 0) return 0
      else {
        const invalidTicket = multiplePasien.filter(_item => _item.type && _item.type === 'FAILED')
        return invalidTicket.length
      }
    }
  },
  props: {
    timeOut: {
      type: Number,
      default: 10
    },
    ticketDetail: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    AnimationCheck,
    ButtonTimeOut,
    TableAntrian,
    TableAntrianMultiple
  },
  methods: {
    changeOfData (lang, text, change) {
      const moreFunction = new MoreFunction()
      return moreFunction.changeLangwithParam(lang, text, change)
    },
    goToRoute () {
      window.machineKiosk.send({
        type: 'button:cancel'
      })
    }
  }
}
</script>
