<template>
    <div class="table-antrian-tab mx-auto">
      <slot />
      <template v-if="isShow">
        <div class="bg-table-blur" ></div>
        <div v-for="list,index in selectAntrian" :key="index"  class="row-table-success show-data-event grid-template-table" :ref="`antrian-data-${index}`" >
                <template v-for="detail2, index2 in listObjMapper" :key="index2">
                    <detail-table-antrian :class-set="classSet" :text-antrian="selectAntrian[index][detail2]" />
                </template>
        </div>
      </template>
      <div class="row-table-success show-list" v-for="index in 5" :key="index" :class="index === 5 ? 'border-radius-bottom': ''">
          <template v-if="listTable[index-1]">
              <template v-for="detail, index2 in listObjMapper" :key="index2">
                <detail-table-antrian :class-set="[...classSet, index === 5  ? 'border-10': '']" :text-antrian="listTable[index - 1][detail]" />
              </template>
          </template>
          <template v-else>
              <div class="cell" :class="classSet[0]" v-for="indexData in listObjMapper" :key="indexData">&nbsp;</div>
          </template>
      </div>
    </div>
  </template>

<script>
import { mapState } from 'vuex'
import DetailTableAntrian from './detailTableAntrian.vue'
export default {
  data () {
    return {
      selectAntrian: [],
      isTimeOut: '',
      isShow: false
    }
  },
  components: {
    DetailTableAntrian
  },
  computed: {
    ...mapState({
      dataTimeOut: state => state.dataTimeOut
    }),
    listTable () {
      return this.dataList.list ? this.dataList.list : []
    },
    listObjMapper () {
      return this.dataList.objectShow
    }
  },
  props: {
    dataList: {
      type: Object,
      default: () => {}
    },
    classSet: {
      type: Array,
      default: () => ['fs-42', 'fs-600', 'c-primary']
    },
    fromKiosk: {
      type: String,
      default: 'antrian'
    }
  },
  watch: {
    listTable (val) {
      if (val && val.length > 0) {
        const selected = this.listTable.filter(list => list.selected)
        this.selectAntrian = selected
        const indexSelected = this.listTable.findIndex(list => list.selected)
        this.isTimeOut = setTimeout(() => {
          this.isShow = true
          setTimeout(() => {
            const columnTable = this.listObjMapper.length > 0 ? this.listObjMapper.length : 1
            for (let i = 0; i < this.selectAntrian.length; i++) {
              const percentage = this.fromKiosk === 'antrian' ? (indexSelected + i) * 20 : (13 + (indexSelected + i) * 17)
              const key = `antrian-data-${i}`
              this.$refs[key][0].style.top = `${percentage}%`
              this.$refs[key][0].style.gridTemplateColumns = `repeat(${columnTable}, auto)`
              this.$refs[key][0].classList.add('opacity-animate')
            }
          }, 100)
        }, 1000 * this.dataTimeOut.overlayTableCreateAntrian)
      }
    }
  },
  unmounted () {
    clearTimeout(this.isTimeOut)
  },
  mounted () {
    if (this.listTable && this.listTable.length > 0) {
      const selected = this.listTable.filter(list => list.selected)
      this.selectAntrian = selected
      const indexSelected = this.listTable.findIndex(list => list.selected)
      this.isTimeOut = setTimeout(() => {
        this.isShow = true
        setTimeout(() => {
          const columnTable = this.listObjMapper.length > 0 ? this.listObjMapper.length : 1
          for (let i = 0; i < selected.length; i++) {
            const percentage = this.fromKiosk === 'antrian' ? (indexSelected + i) * 20 : (13 + (indexSelected + i) * 17)
            const grid = this.fromKiosk === 'antrian' ? `repeat(${columnTable}, auto)` : `160px repeat(${columnTable - 1}, auto)`
            const key = `antrian-data-${i}`
            this.$refs[key][0].style.top = `${percentage}%`
            this.$refs[key][0].style.gridTemplateColumns = `${grid}`
            this.$refs[key][0].classList.add('opacity-animate')
          }
        }, 500)
      }, 1000 * this.dataTimeOut.overlayTableCreateAntrian)
    }
  }
}
</script>
  <style>
      .grid-template-table {
          display: grid;
      }
      .margin-antrian-3column {
         margin: 0 140px;
      }

      .margin-antrian-4column {
         margin: 0 80px;
      }

      .table-antrian-tab{
          position: relative;
          display: table;
          width: 100%;
          border-collapse: collapse;
          /* filter: drop-shadow(0px 4px 24px #CFDFF5); */
          /* box-shadow: 0px 4px 24px #CFDFF5; */
          border-radius: 10px;
          background: white;
      }
      .table-antrian-tab > .show-list:nth-child(even){
          background-color: #E9F2FF;
      }
      .bg-table-blur{
          border-radius: 10px;
         animation: backdrop 1s ease-in-out;
         -webkit-animation-fill-mode: forwards;
          width: 100%;
          position: absolute;
           z-index: 4;
           height: 100%;
           top:0;
      }

      .show-data-event{
          position: absolute;
          background: white;
          width: calc(100% + 15px);
          /* box-shadow: 0px 4px 24px #8b0219; */
          box-shadow: 0px 0px 30px #F01478;
          margin-left: -15px;
          z-index: 5;
          opacity: 0;
      }

      @keyframes backdrop {
        from{
           background: transparent;
        }

        to {
           /* background: rgba(0, 49, 94, 0.4); */
           background: #00315E;
           opacity: 0.5;
        }
      }
  </style>
