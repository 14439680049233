<template>
    <div class="bg-biru-E p-4 mb-5" v-if="detailDokter.waiting.length > 0">
        <span class="fw-500 fs-42 c-deep">Namun, ada jadwal booking yang belum Anda selesaikan</span>
        <dokter :dokter="item"  v-for="item, index in detailDokter.waiting" :key="index"/>
    </div>
    <!-- <div class="bg-biru-E p-4 mb-5"  v-if="detailDokter.waiting.length > 0">
        <span class="fw-500 fs-42 c-deep">Saat ini nomor tiket Anda masih diantrikan pada:</span>
        <dokter :dokter="item"  v-for="item, index in detailDokter.waiting" :key="index"/>
    </div> -->
    <!-- <div v-if="detailDokter.dokter_wait && detailDokter.dokter_wait.length > 1">
        <span class="fw-500 fs-42 c-deep">Namun, ada jadwal booking yang belum Anda selesaikan</span>
        <div v-for="dokter in detailDokter.dokter_wait" :key="dokter.id">
            <dokter :dokter="dokter"  />
        </div>
        <div v-if="detailDokter.poliWaiting" class="mt-5">
           <queue-now :waiting="detailDokter.poliWaiting"></queue-now>
        </div>
    </div>
    <div v-else-if="detailDokter.poliWaiting">
        <queue-now :waiting="detailDokter.poliWaiting"></queue-now>
        <table-antrian :data-list="dataList"/>
    </div> -->
</template>

<script>
import Dokter from '@/components/ScanTicket/DokterScan.vue'
// import QueueNow from './QueueNow.vue'
// import TableAntrian from '../Status/TableAntrian.vue'
export default {
  components: {
    Dokter
    // QueueNow,
    // TableAntrian
  },
  props: {
    detailDokter: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      dataList: [
        {
          id: 15,
          numberAntrian: '0032',
          status: 'Waiting',
          selected: true
        },
        {
          id: 16,
          numberAntrian: '0033',
          status: 'Waiting'
        },
        {
          id: 17,
          numberAntrian: '0031',
          status: 'Waiting'
        }
      ]
    }
  },
  mounted () {
    console.log('component detail', this.detailDokter)
  }
}
</script>

<style>

</style>
