<template>
    <div class=" padding-dokter-desc" :class="classBorder ? 'border-dokter-select': ''">
        <!-- <input type="checkbox" class="form-check-input" :id="`dokter-${detailDokter.id}`" v-model="dokter">
        <span class="checkmark"></span>
        <label :for="`dokter-${detailDokter.id}`" class="pl-70 w-100">
            <p class="fs-38 fw-600 mb-0 ellipsis-dokter">{{detailDokter.name}}</p>
            <p class="fs-32 fw-400 mb-0">{{detailDokter.poli}}</p>
            <p class="fs-32 fw-400 mb-0">{{detailDokter.time}}</p>
        </label> -->
        <p class="fs-38 fw-600 mb-0 ellipsis-dokter">{{detailDokter.name}}</p>
        <div class="d-flex">
          <p class="fs-32 fw-400 mb-0">{{detailDokter.poli}}</p>
          <p class="fs-32 fw-400 mb-0 ml-auto">{{detailDokter.time}}</p>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    detailDokter: {
      type: Object,
      default: () => {}
    },
    classBorder: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      dokter: true
    }
  },
  watch: {
    dokter (val) {
      this.$emit('pick-dokter', { selected: val, id: this.detailDokter.id })
    }
  }
}
</script>

<style>
  .padding-dokter-desc{
    padding: 20px 0;
  }
</style>
