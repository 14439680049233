<template>
  <!-- <div class="h-100"> -->
    <success-create :time-out="ticketDetail.config.timeOutCancel" :ticket-detail="ticketDetail" v-if="ticketDetail.page === page.PAGE_ANTRIAN_SUCCESS"/>
    <list-antrian-dokter :delay-linear="ticketDetail.config" :list-dokter="ticketDetail.listBooking"  v-else-if="ticketDetail.page === page.PAGE_ANTRIAN_LIST_DOKTER" @print="print"/>
    <antrian-mode v-else-if="ticketDetail.page === page.PAGE_MODE_ANTRIAN" :detail="ticketDetail.detailAntrian" :delay-linear="ticketDetail.config" :unit-antrian="ticketDetail.selectUnit"/>
    <error-qr v-else-if="ticketDetail.page === page.PAGE_ANTRIAN_ERROR" :time-out="ticketDetail.config.timeOutCancel" :type-error="ticketDetail.type" />
    <create-queue v-else-if="ticketDetail.page === page.PAGE_ANTRIAN_LIST_SUCCESS" :list-antrian="ticketDetail.listTable" :time-out="ticketDetail.config.timeOutCancel" :type-success="ticketDetail.successTicket" :error-data="ticketDetail.doubleCheckIn" :name-unit="ticketDetail.detailAntrian"></create-queue>
    <show-ticket-with-number-telepon :select-pasien="ticketDetail.selectPasienMultiple" :delayTime="ticketDetail.config" :disabled-button="ticketDetail.disabled" :list-pasien="ticketDetail.listPasien" v-else-if="ticketDetail.page === page.PAGE_DOUBLE_PASIEN" :phone-number="ticketDetail.param"></show-ticket-with-number-telepon>
  <!-- </div> -->
</template>

<script>
import SuccessCreate from './SuccessCreate.vue'
import ErrorQr from '@/components/Status/ErrorQr.vue'
import ListAntrianDokter from '@/components/Status/ListAntrianDokter.vue'
import AntrianMode from '@/components/Status/AntrianMode.vue'
import CreateQueue from '@/components/ScanTicket/CreateQueue'
import ShowTicketWithNumberTelepon from '@/views/ShowTicketTeleponDouble.vue'

import ENUM_SETUP from '@/enum/typeSetup'
export default {
  data () {
    return {
      page: ENUM_SETUP
    }
  },
  components: {
    SuccessCreate,
    ErrorQr,
    ListAntrianDokter,
    AntrianMode,
    CreateQueue,
    ShowTicketWithNumberTelepon
  },
  props: {
    ticketDetail: {
      type: Object,
      default: () => {}
    }
  },
  mounted () {
  },
  methods: {
    print (evt) {
      this.$emit('print', evt)
    }
  }
}
</script>

<style>

</style>
