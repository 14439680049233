import ConvertTime from '@/helpers/convertTime'

class PrintData {
  header;
  antrian;
  dokter;
  qrCode;
  constructor (data) {
    this.header = 'RS. MANDAYA ROYAL PURI'
    this.antrian = '0032'
    this.qrCode = '{"type":"antrian","code":2}'
    this.time = this.convertTime(new Date())
  }

  convertTime (now) {
    const day = new ConvertTime().getNameDay(now.getDay())
    const date = now.getDate()
    const month = new ConvertTime().getNameMonth(now.getMonth())
    const year = now.getFullYear()
    const hour = now.getHours().toString().padStart(2, '0')
    const minute = now.getMinutes().toString().padStart(2, '0')
    const second = now.getSeconds().toString().padStart(2, '0')
    return `${day}, ${date} ${month} ${year} ${hour}:${minute}:${second}`
  }
}

export default PrintData
