<template>
  <div class=" position-absolute d-flex flex-column height-antrian">
    <div class="text-center ">
     <img src="@/assets/img/warning.png" class="img-warning m-auto"/>
     <p class="fs-52 fw-700 c-deep">
        {{$l.detectLanguage('Anda Akan check-in ke')}} <span class="c-pink-dokter">{{unitAntrian.name}}</span> </p>
      <div class="row  ">
          <div class="col-12 text-left ">
            <body-scan-info :detail-dokter="detail" />
          </div>
      </div>
    </div>
      <div class="row mt-auto">
         <div class="col-12 mb-40">
              <p class="c-primary fw-700 fs-42 text-left">
              {{ $l.detectLanguage('Apakah Anda yakin sudah menyelesaikan list di atas dan ingin melanjutkan check-in ke') }} {{unitAntrian.name}}?
                <!-- Apakah Anda yakin ingin memindahkan antrian dan melanjutkan check-in ? -->
              </p>
          </div>
          <div class="col-6">
              <button-linear-cancel :data-time-out="delayLinear"  @cancel="backToMenu"  ref="button-cancel"/>
          </div>
          <div class="col-6">
              <button  class="btn-lanjutkan fs-42 fw-600 btn-block" @click="nextAction">{{ $l.detectLanguage('proceed') }}</button>
          </div>
      </div>
  </div>
</template>

<script>
import ButtonLinearCancel from '@/components/Button/ButtonLinearCancel'
import BodyScanInfo from '@/components/ScanTicket/BodyScanInfo'
export default {
  components: {
    ButtonLinearCancel,
    BodyScanInfo
  },
  props: {
    unitAntrian: {
      type: Object,
      default: () => {}
    },
    detail: {
      type: Object,
      default: () => {}
    },
    delayLinear: {
      type: Object,
      default: () => {}
    }
  },
  mounted () {
    document.addEventListener('click', this.reset)
  },
  beforeUnmount () {
    document.removeEventListener('click', this.reset)
  },
  methods: {
    reset () {
      this.$refs['button-cancel'].resetBackgroundLinearButton()
      window.machineKiosk.send({
        type: 'click:anywhere'
      })
    },
    backToMenu () {
      // this.$router.push({ name: 'Menu' })
      window.machineKiosk.send({
        type: 'button:batalkan'
      })
    },
    nextAction () {
      window.machineKiosk.send({
        type: 'button:lanjutkan'
      })
      // this.emitter.emit('change-create-ticket', true)
    }
  }
}
</script>

<style>
  .img-warning{
    width: 112px;
  }

  .height-antrian{
    height: calc(100% - 80px);
    width: calc(100% - 146px);
  }
</style>
