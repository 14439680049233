<template>
  <div class="text-center">
    <template v-if="typeSuccess">
      <p class="fs-52 fw-700 c-deep m-0">{{ $l.detectLanguage('ANTRIAN BERHASIL DIBUAT') }}</p>
      <animation-check />
      <p class="c-primary fs-38 fw-600 mt-4">
        {{ $l.detectLanguage('Silakan menunggu di antrian') }}
        <strong class="fs-42">{{nameUnitSelected}}</strong>
        {{ $l.detectLanguage('queue lounge') }}
      </p>
      <table-antrian :data-list="listAntrian" style="width: 90%"/>
    </template>
    <template v-else>
      <img src="@/assets/img/warning.png" />
      <template v-if="!errorData">
        <p class="fs-52 fw-700 c-deep mb-40">Anda belum melakukan registrasi</p>
      </template>
      <template v-else>
        <p class="fs-52 fw-700 c-deep mb-40">Anda akan check-in ke <span class="c-pink-dokter">{{nameUnitSelected}}</span></p>
      </template>
      <div class="bg-biru-E bg-container-error">
        <p class="fs-42 fw-500 c-primary text-left">Saat ini nomor tiket Anda sudah terdaftar pada antrian
          <span class="fw-700">{{nameUnitSelected}}</span>
      </p>
        <table-antrian :data-list="listAntrian" style="width: 90%"/>
      </div>
    </template>
    <template v-if="typeSuccess">
    <p class="fs-38 fw-600 mt-5 c-primary">{{ $l.detectLanguage('Nomor Tiket') }} <span class="font-weight-bold c-pink-dokter">{{listAntrian.ticket}}</span></p>
    <p class="fs-38 c-warning-A fw-600">{{ $l.detectLanguage('Periksa kembali tiket Anda dengan yang tertera di layar kiosk') }}</p>
    </template>
    <template v-else>
      <p class="fs-38 fw-600 mt-5 c-primary">Silakan menunggu pada antrian <span class="fw-700">{{nameUnitSelected}} </span></p>
    </template>
    <button-time-out :time="timeOut" @click="goToRoute()" class="fs-32"/>
  </div>
</template>

<script>
import TableAntrian from '../Status/TableAntrianResponsiveWithout.vue'
import AnimationCheck from '@/components/Button/AnimationCheck.vue'
import ButtonTimeOut from '@/components/Button/ButtonTimeOut.vue'

export default {
  props: {
    timeOut: {
      type: Number,
      default: 10
    },
    listAntrian: {
      type: Object,
      default: () => {}
    },
    typeSuccess: {
      type: Boolean,
      default: true
    },
    errorData: {
      type: Boolean,
      default: true
    },
    isDoubleCheck: {
      type: Boolean,
      default: false
    },
    nameUnit: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    nameUnitSelected () {
      const nameUnit = this.nameUnit && this.nameUnit.selectUnit && this.nameUnit.selectUnit.name ? this.nameUnit.selectUnit.name : '-'
      return nameUnit
    }
  },
  components: {
    TableAntrian,
    AnimationCheck,
    ButtonTimeOut
  },
  data () {
    return {
      // dataList: [
      //   {
      //     id: 15,
      //     numberAntrian: '0012',
      //     time: '14:52',
      //     status: 'Waiting'
      //   },
      //   {
      //     id: 16,
      //     numberAntrian: '0026',
      //     time: '14:54',
      //     status: 'Waiting'
      //   },
      //   {
      //     id: 17,
      //     numberAntrian: '0032',
      //     time: '14:59',
      //     status: 'Waiting',
      //     selected: true
      //   }
      // ]
    }
  },
  created () {
    // if (!this.errorData) {
    //   this.listAntrian.map(item => {
    //     delete item.time
    //     return item
    //   })
    // }
  },
  methods: {
    goToRoute () {
      window.machineKiosk.send({
        type: 'button:ok'
      })
    }
  }
}
</script>

<style>

</style>
