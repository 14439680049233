<template>
  <div class="c-leisure-blue">
    <label class="fs-38 fw-700 ellipsis-dokter">{{dokter.nama}}</label>
    <div class="fs-32 fw-500 d-flow-root">
        <span class="float-left">{{dokter.spesialis}}</span>
        <span class="float-right">{{dokter.time}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dokter: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style>
 .d-flow-root{
  display: flow-root;
 }
</style>
