<template>
  <div class="text-center">
     <img class="mb-24" src="@/assets/img/information.png" />
     <p class="fs-52 fw-700 c-deep">{{ $l.detectLanguage('Anda Memiliki') }} <span class="c-pink-dokter">{{listDokter.length}}</span> {{ $l.detectLanguage('jadwal booking hari ini') }}</p>
     <div class="row text-left c-pink-dokter list-dokter-antrian box-dokter-booking">
        <div class="col-12 padding-dokter-select">
            <div v-for="dokter, index in listDokter" :key="dokter.id" >
              <check-box-dokter :classBorder="index < listDokter.length - 1" :detail-dokter="dokter" @click="resetTimeOut" @pick-dokter="selectDokter"/>
            </div>
        </div>
     </div>
     <div class="row mx-0 mb-40">
      <div class="media">
           <img
                src="@/assets/img/information-A.png"
                class="pt-2 mr-3"
                width="32"
                alt=""
              />
          <div class="media-body c-primary fw-600 fs-32 text-left ml-2">
            {{ $l.detectLanguage('Jika Anda ingin ada perubahan jadwal silakan konfirmasi petugas pendaftaran kami.') }}
          </div>
      </div>
     </div>
    <div class="row">
        <div class="col-6">
            <button-linear-cancel :data-time-out="delayLinear" @cancel="routeTo" ref="button-cancel"/>
        </div>
        <div class="col-6">
            <button  class="btn-lanjutkan fs-42 fw-600 btn-block" @click.stop="goTo">{{ $l.detectLanguage('CETAK') }}</button>
        </div>
    </div>
  </div>
</template>

<script>
import ButtonLinearCancel from '@/components/Button/ButtonLinearCancel'
import CheckBoxDokter from './CheckBoxDokter.vue'
// import mitt from 'mitt'
// const emitter = mitt()
export default {
  components: {
    ButtonLinearCancel,
    CheckBoxDokter
  },
  mounted () {
    document.addEventListener('click', this.reset)
  },
  beforeUnmount () {
    document.removeEventListener('click', this.reset)
  },
  props: {
    listDokter: {
      type: Array,
      default: () => []
    },
    delayLinear: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      listSelectDokter: []
    }
  },
  methods: {
    reset (event) {
      event.preventDefault()
      this.$refs['button-cancel'].resetBackgroundLinearButton()
      window.machineKiosk.send({
        type: 'click:anywhere'
      })
    },
    routeTo () {
      // this.$router.push({ name: 'Menu' })
      window.machineKiosk.send({
        type: 'button:batalkan'
      })
    },
    goTo () {
      window.machineKiosk.send({
        type: 'button:cetak'
      })
      // this.$emit('print', this.listSelectDokter)
      // this.emitter.emit('back-to-ticket', true)
    },
    resetTimeOut () {
      this.$refs['button-cancel'].resetBackgroundLinearButton()
      window.machineKiosk.send({
        type: 'click:anywhere'
      })
    },
    selectDokter (val) {
      const getIdDokter = this.listSelectDokter.findIndex(item => item.id === val.id)
      if (getIdDokter >= 0) {
        this.listSelectDokter[getIdDokter] = val
      } else {
        this.listSelectDokter.push(val)
      }
    }
  }
}
</script>

<style>
.c-pink-dokter input[type='checkbox'] {
    accent-color: var(--pink-care-dokter);
}

.border-dokter-select{
  border-bottom: 2px solid #CFDFF5;
}

.padding-dokter-select{
  padding: 20px 40px;
}
</style>
